var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "el-menu",
        {
          staticClass: "sidebar-el-menu",
          attrs: {
            "default-active": _vm.onRoutes,
            collapse: _vm.collapse,
            "background-color": "#324157",
            "text-color": "#bfcbd9",
            "active-text-color": "#20a0ff",
            "unique-opened": "",
            router: ""
          }
        },
        [
          _vm._l(_vm.menuList, function(item, index) {
            return [
              item.children
                ? [
                    _c(
                      "el-submenu",
                      {
                        key: item.module_id,
                        attrs: { index: item.module_id + "" }
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c("i", { staticClass: "el-icon-eleme" }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ]),
                        _vm._l(item.children, function(subItem) {
                          return [
                            subItem.children
                              ? _c(
                                  "el-submenu",
                                  {
                                    key: subItem.module_id,
                                    attrs: { index: subItem.url + "" }
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(_vm._s(subItem.name))
                                    ]),
                                    _vm._l(subItem.children, function(
                                      threeItem,
                                      i
                                    ) {
                                      return _c(
                                        "el-menu-item",
                                        {
                                          key: i,
                                          attrs: { index: threeItem.url + "" }
                                        },
                                        [_vm._v(_vm._s(threeItem.name))]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _c(
                                  "el-menu-item",
                                  {
                                    key: subItem.module_id,
                                    attrs: { index: subItem.url + "" }
                                  },
                                  [_vm._v(_vm._s(subItem.name))]
                                )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                : [
                    _c(
                      "el-menu-item",
                      { key: item.module_id, attrs: { index: item.url + "" } },
                      [
                        _c("i", { staticClass: "el-icon-tickets" }),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ]
                    )
                  ]
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }