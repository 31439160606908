<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
            <template v-for="(item,index) in menuList">
                <template v-if="item.children">
                    <el-submenu :index="item.module_id+''" :key="item.module_id">
                        <template slot="title">
                            <i class="el-icon-eleme"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>
                        <template v-for="subItem in item.children">
                            <el-submenu v-if="subItem.children" :index="subItem.url+''" :key="subItem.module_id">
                                <template slot="title">{{ subItem.name }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.children" :key="i" :index="threeItem.url+''">{{
                                    threeItem.name
                                }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="subItem.url+''" :key="subItem.module_id">{{ subItem.name }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.url+''" :key="item.module_id">
                        <i class="el-icon-tickets"></i>
                        <span slot="title">{{ item.name }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import bus from '../common/bus';
import cache from '@/utils/cache';
import config from '@/utils/config';
import dictionaryUtil from '@/utils/dictionaryUtil';
import Config from '@/utils/config';
export default {
    data() {
        return {
            currentTenant:{},
            collapse: false,
            menuList: [],
            tenantList:[]
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace('/', '');
        }
    },
    created() {
        // 通过 Event Bus 进行组件间通信，来折叠侧边栏
        bus.$on('collapse', msg => {
            this.collapse = msg;
            bus.$emit('collapse-content', msg);
        });
    },
    mounted() {
        this.getMenu()
        bus.$on('changeTanantMenu', msg => {
            console.log('变更品牌',msg)        
            this.getCommonValues();
        });
    },
    methods: {
         //租户列表
        getbyUser() {
            this.$store.dispatch('getbyUser', {}).then(res => {
                res.forEach(element => {
                    if (element.app_id == Config.appId && element.permission) {
                        this.tenantList.push(element);
                    }
                });
                setTimeout(() =>{
                    if(this.tenantList.length>0){
                        this.currentTenant = this.tenantList[0];
                    }
                })             	                      
            });
        },
        getMenu() {
            this.$store.dispatch('getMenu', {
			}).then(res => {
                let home = { name: '系统主页', module_id: '00001', url: '/home' };
                this.menuList = res;
                // this.menuList.unshift(home);
            });
        },
        getCommonValues() {
            this.getMenu()
            this.currentTenant=cache.get(config.currentTenant)
            if(JSON.stringify(this.currentTenant) == '{}'){
                this.getbyUser()
            }
            this.$store.dispatch('getDict', 'countrys').then(res => {});
            this.$store.dispatch('getDict', 'platform').then(res => {});
            this.$store.dispatch('getDict', 'lang').then(res => {});
			this.$store.dispatch('getDict', 'brand').then(res => {});
			this.$store.dispatch('getDict', 'banner_position').then(res => {});
            this.$store.dispatch('getDict', 'banner_jump_type').then(res => {});
            this.$store.dispatch('getDict', 'homePageCopyWriter_jump_type').then(res => {});
            this.$store.dispatch('getDict', 'user_type').then(res => {});
            this.$store.dispatch('getDict', 'country_currency').then(res => {});
            this.$store.dispatch('getDict', 'goods_tag_code').then(res => {});      
            this.$store.dispatch('getOperator',{}).then(res => {
                dictionaryUtil.setDict('operator',res)
			});
            // this.$store.dispatch('setPermission',{
            //     tenantId: this.currentTenant.tenant_id
            // }).then(res => {
            //     cache.set(config.permission,res)
			// });
                  				
        }
    },
    destroyed() {
        bus.$off('changeTanantMenu', {});
    }
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
